import { render, staticRenderFns } from "./PortalHelp.vue?vue&type=template&id=5a34e28c&scoped=true"
import script from "./PortalHelp.vue?vue&type=script&lang=js"
export * from "./PortalHelp.vue?vue&type=script&lang=js"
import style0 from "./PortalHelp.vue?vue&type=style&index=0&id=5a34e28c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a34e28c",
  null
  
)

export default component.exports