<template>
  <div>
    <div class="v-carousel">
      <h2 class="fieldhelp-title">Homepage</h2>
      <v-carousel
        height="500"
        hide-delimiter-background
        show-arrows
        color="black"
      >
        <v-carousel-item v-for="(item, i) in homepages" :key="i">
          <v-sheet color="transparent" height="100%">
            <div class="d-flex fill-height justify-between align-center">
              <div
                class="fill-height d-flex justify-center align-center"
                style="width: 120rem"
              >
                <img
                  :src="`https://ik.imagekit.io/tiuh2lft4/fieldhelp/homepage${
                    i + 1
                  }.png`"
                  alt="Homepage Image"
                  :height="item.height"
                />
              </div>
              <div class="fill-height ml-4 my-5 d-flex justify-end w-50">
                <v-card
                  class="fill-height rounded-0 d-flex"
                  elevation="0"
                  style="overflow: hidden; background-color: transparent"
                >
                  <v-card-text class="v-card-text" style="overflow: auto">
                    <div class="text-h3 mb-4">{{ item.title }}</div>
                    <p class="text-body-1">
                      <span class="font-weight-bold">Description:</span>
                      {{ item.description }}
                    </p>
                    <p class="text-body-1">
                      <span class="font-weight-bold">Functionality:</span>
                      {{ item.functionality }}
                    </p>
                    <p class="text-body-1">
                      <span class="font-weight-bold mr-1">Components:</span>
                      <v-chip
                        v-for="(component, i) in item.components"
                        :key="i"
                        class="mx-1"
                        >{{ component }}</v-chip
                      >
                    </p>
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </div>
    <v-divider class=".v-divider"></v-divider>
    <div class="v-carousel">
      <h2 class="fieldhelp-title">Datagrid</h2>
      <v-carousel
        height="500"
        hide-delimiter-background
        show-arrows
        color="black"
      >
        <v-carousel-item v-for="(item, i) in datagrids" :key="i">
          <v-sheet color="transparent" height="100%">
            <div class="d-flex fill-height justify-between align-center">
              <div
                class="fill-height d-flex justify-center align-center"
                style="width: 120rem"
              >
                <img
                  :src="`https://ik.imagekit.io/tiuh2lft4/fieldhelp/datagrid${
                    i + 1
                  }.png`"
                  alt="Datagrid Image"
                  :height="item.height"
                />
              </div>
              <div class="fill-height ml-4 my-5 d-flex justify-end w-50">
                <v-card
                  class="fill-height rounded-0 d-flex"
                  elevation="0"
                  style="overflow: hidden; background-color: transparent"
                >
                  <v-card-text class="v-card-text" style="overflow: auto">
                    <div class="text-h3 mb-4">{{ item.title }}</div>
                    <p class="text-body-1">
                      <span class="font-weight-bold">Description:</span>
                      {{ item.description }}
                    </p>
                    <p class="text-body-1">
                      <span class="font-weight-bold">Functionality:</span>
                      {{ item.functionality }}
                    </p>
                    <p class="text-body-1">
                      <span class="font-weight-bold mr-1">Components:</span>
                      <v-chip
                        v-for="(component, i) in item.components"
                        :key="i"
                        class="mx-1"
                        >{{ component }}</v-chip
                      >
                    </p>
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </div>
    <v-divider class=".v-divider"></v-divider>
  </div>
</template>

<script>
import DecodeJWT from "../DecodeJWT";

export default {
  mounted() {
    let token = localStorage.getItem("MTNToken");
    if (token == "" || !token) {
      this.$router.push("/login");
    } else {
      var info = DecodeJWT(token);
      if (info.exp <= new Date().getTime() / 1000) {
        this.$router.push("/login");
      }
    }
  },
  data() {
    return {
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
      homepages: [
        {
          title: "Homepage",
          height: "80%",
          description:
            "The homepage is typically the default page displayed when users access your application. It usually contains an overview of the application or important content such as news, greetings, or brief instructions.",
          functionality:
            "The homepage usually doesn't have many complex features but instead displays basic information. Depending on the needs, you can add sections like featured articles, important links, or interesting images.",
          components: ["Card Group", "Service Request"],
        },
        {
          title: "Service Request",
          height: "100%",
          description:
            "A service request description outlines the nature, location, and urgency of a maintenance or repair issue. It aids in efficient response and resolution by providing essential details, helping maintenance teams understand the problem's scope and prioritize tasks, ultimately minimizing downtime and costs.",
          functionality:
            "Streamlines the process of reporting and managing maintenance or repair issues. It allows users to submit, track, and prioritize service requests, facilitating quick response, efficient resource allocation, and effective issue resolution, ultimately reducing downtime and related expenses.",
          components: ["Info Textfield", "Submit Button"],
        },
        {
          title: "Card Group",
          height: "100%",
          description:
            "A card is a compact visual element used in user interfaces to display information, often in the form of text, images, or other media. Cards are typically designed to present concise, self-contained content, making it easier for users to scan and interact with information in an organized and visually appealing manner.",
          functionality:
            "pertains to the capabilities and features of card-based user interfaces. Cards are used to present concise and organized information, often with interactive elements like buttons or links. Users can quickly scan, access, and interact with content, making cards a popular choice for displaying various types of information in a user-friendly manner.",
          components: ["Customer Bar", "Card"],
        },
        {
          title: "Card",
          height: "50%",
          description:
            "A card is a compact visual element used in user interfaces to display information, often in the form of text, images, or other media. Cards are typically designed to present concise, self-contained content, making it easier for users to scan and interact with information in an organized and visually appealing manner.",
          functionality:
            "pertains to the capabilities and features of card-based user interfaces. Cards are used to present concise and organized information, often with interactive elements like buttons or links. Users can quickly scan, access, and interact with content, making cards a popular choice for displaying various types of information in a user-friendly manner.",
          components: ["Icon", "Label"],
        },
      ],
      datagrids: [
        {
          title: "Datagrid",
          height: "80%",
          description:
            "A datagrid is a tabular or grid-based user interface element used to display and organize structured data. It typically presents data in rows and columns, allowing users to view, sort, filter, and interact with information efficiently. Datagrids are commonly used in applications and websites to present data in an organized and readable format.",
          functionality:
            "Refers to the features and capabilities of a grid-based user interface component used for displaying structured data. Users can interact with data presented in rows and columns, sorting, filtering, and sometimes editing or selecting items. Datagrids enhance data organization and accessibility, making them essential for data-driven applications and systems.",
          components: ["Header", "Detail"],
        },
        {
          title: "Header",
          height: "60%",
          description:
            "A header is a top section of a webpage or document that typically contains branding, navigation menus, and essential information. It serves as an entry point to the content below and helps users quickly identify the source, purpose, and navigation options of the page. Headers improve user experience and site recognition.",
          functionality:
            "Header functionality encompasses the features and role of the top section in a webpage or document. It often includes branding, site navigation, search options, and links to key sections. Headers provide easy access to essential information, enhance user navigation, and reinforce the identity of the website or document. They play a crucial role in user experience.",
          components: ["Search Bar", "Data Table"],
        },
        {
          title: "Detail",
          height: "60%",
          description:
            " In user interfaces, a detail refers to additional information or options that can be accessed when interacting with a specific element or item. Clicking on or interacting with an element often reveals details, offering in-depth information, settings, or actions related to that particular item, enhancing the user's understanding and interaction with the content.",
          functionality:
            "refers to the features and capabilities that allow users to access specific information or data in a user interface. Details are typically accessed when users click or interact with an element, providing more in-depth information, options, or actions related to a particular item, feature, or aspect of the interface, enhancing user engagement.",
          components: ["Search Bar", "Data Table"],
        },
        {
          title: "Data Table",
          height: "50%",
          description:
            "A data table is a structured grid-like format used to display organized information, such as data or statistics. It typically consists of rows and columns, with each row representing a data record and each column representing a specific attribute or data field. Data tables facilitate easy data comparison and analysis.",
          functionality:
            "Encompasses the features and capabilities of grid-based interfaces for displaying structured data. Users can view, sort, filter, and sometimes edit data, making it a valuable tool for data analysis. Data tables enable efficient data organization, comparison, and provide user-friendly ways to interact with large datasets in applications and websites.",
          components: ["Row", "Column", "Pagination"],
        },
        {
          title: "Search Bar",
          height: "30%",
          description:
            "A search bar is a user interface element, often a text box, that allows users to input keywords or queries to search for specific information within a website, application, or database. When users enter a search term and initiate the search, the system returns relevant results, making content discovery efficient and user-friendly.",
          functionality:
            "enables users to input search queries or keywords, initiating a search process within a website, application, or database. The system then retrieves and displays relevant results based on the user's input, enhancing content discovery and making it convenient for users to find specific information quickly and effectively.",
          components: ["Title", "Textfield"],
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
/* CSS của component */

.v-carousel {
  padding: 20px;
  margin-bottom: 20px;
}

.v-card-text {
  padding: 30px;
}

.v-divider {
  margin-left: 200px;
  margin-right: 200px;
}

.fieldhelp-title {
  padding-left: 20px;
}
</style>
